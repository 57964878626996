.component {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  margin-bottom: 70px;
}

.component > div {
  max-width: 60%;
  color: white;
  padding: 40px;
  text-align: center;
  /* border-top:1px solid #fff; */
  margin-top: 40px;
  box-shadow: 0 0 7px rgb(0, 116, 227), 0 0 10px rgb(0, 116, 227),
    0 0 rgb(0, 116, 227), 0 0 42px rgb(0, 116, 227);
}

.component > div > p {
  line-height: 1.6;
}

.neonText {
  color: #fff;
  text-shadow: 0 0 7px rgb(0, 116, 227), 0 0 10px rgb(0, 116, 227),
    0 0 rgb(0, 116, 227), 0 0 42px rgb(0, 116, 227), 0 0 82px rgb(0, 116, 227),
    0 0 92px rgb(0, 116, 227);
}

.flipCard{
    width: 200px;
}
.cardFront {
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 10px;
  backdrop-filter: blur(2px);
  box-sizing: content-box;
  padding: 0;
}

.cardFront > img {
   height: 200px;
}

.cardBack {
  background: rgba(16, 18, 27, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 10px;
  backdrop-filter: blur(2px);
}
