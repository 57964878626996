.mainFooter {
  color: white;
  background-color: rgb(0, 0, 0, 0.1);
  padding-top: 3em;
  /* position: relative; */
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction:column; */
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}
.column1 {
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  flex-direction: column;
  /* background: #f5f5f5; */
  flex:0.4 !important;
  text-align:left !important;
  margin-left:8%
}

.column2 {
  display: flex;
  align-items: flex-end !important;
  justify-content: flex-end !important;
  flex-direction: column;
  /* background: #f5f5f5; */
  flex:0.3 !important;
  text-align:right !important;
  /* margin-right:8% */
}

.anchortags {
  text-decoration: none;
  color: #ffffff;
  line-height:1.9!important;
  text-align:right !important;
  margin-right:-8% !important
}
.anchortags:visited {
  color: #ffffff;
}
/* .column>li {
  line-height:1.7!important;
} */

.helpText{
  text-decoration: none;
  color: #ffffff;
  line-height:2!important;
  
}
.helpText:visited {
  color: #ffffff;
}
.neonText {
  color: #fff;
  text-shadow: 0 0 7px rgb(56, 142, 60), 0 0 10px rgb(56, 142, 60),
    0 0 rgb(56, 142, 60), 0 0 42px rgb(56, 142, 60), 0 0 82px rgb(56, 142, 60),
    0 0 92px rgb(56, 142, 60);
    transform: translateX(-75%)
}
