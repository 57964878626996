.courseList {
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  min-height: 100%;
  color: #000000;
  margin:1rem;
}
.wayTable {
  width: 100%;

  height: 100%;
}
.header{
    background-color: #5aa9e6;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    height: 2rem;
    /* border-radius:5px; */
}
.header > tr {
    border-radius: 10px;
    padding:0.5rem;
    color: #ffffff;
}
.creditsTD{
  padding:0.5rem;
  font-size: 0.8rem;
}
.credits {
  margin-left: 5px !important;
  text-align: center;
}
.tableBody {
  background-color: #f3f3f3
}
.tableBody > tr {
  text-align: center;
  border: 1px solid rgb(0, 0, 0);
  padding:0.5rem;
  /* background-color: #000000 */
}
