@media print {
  #dontPrint {
    display: none;
  }
  * {
    -webkit-print-color-adjust: exact !important;
    -moz-print-color-adjust: exact !important;
    -ms-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }
}

@page {
  size: landscape
  /* margin: 10% */
}

.App {
  text-align: center;
  /* color: #ffffff !important; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.mobileText {
  display: none;
}

.neonText {
  color: #fff;
  text-shadow: 0 0 7px rgba(255, 0, 0, 1), 0 0 10px rgba(255, 0, 0, 1),
    0 0 rgba(255, 0, 0, 1), 0 0 42px rgba(255, 0, 0, 1),
    0 0 82px rgba(255, 0, 0, 1), 0 0 92px rgba(255, 0, 0, 1);
  margin-top: 100px;
}

/* .mobileVersion{
  display:block !important
} */

@media (max-width: 666px) {
  .mobileVersion {
    display: none !important;
  }

  .mobileText {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mobileText > h3 {
    margin-top: 50px;
    color: #fff;
    font-size: 15px;
    width: 70%;
    line-height: 2;
  }
}
