.neonText {
  color: #fff;
  text-shadow: 0 0 7px rgba(255, 0, 0, 1), 0 0 10px rgba(255, 0, 0, 1),
    0 0 rgba(255, 0, 0, 1), 0 0 42px rgba(255, 0, 0, 1),
    0 0 82px rgba(255, 0, 0, 1), 0 0 92px rgba(255, 0, 0, 1);
}
.buttonDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.75rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 4rem;
}
.tinyBtnDiv{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 4.5rem;
}

.buttonLook {
  color: #ffffff;
  /* background-color: rgba(0, 116, 227, 1); */
  padding: 0.5rem;
  cursor: pointer;
  background: rgba(0, 116, 227, 1) !important;

  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.buttonLook:hover {
  box-shadow: 0 8px 32px 0 rgba(0, 116, 227, 0.3);
}
.container {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: rgba(196, 196, 196, 0);
  text-align: center;
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 10px;
  /* height: 20rem; */
  user-select: none;
}

.container:hover {
  box-shadow: 0 8px 32px 0 rgba(161, 166, 238, 0.37);
}

.timetableDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.mainTable {
  width: 95%;
  border-spacing: 0.2rem;
  height: 100%;
}

.firstColumn {
  font-weight: 500;
  background-color: rgba(206, 212, 218, 1);
  cursor: default;
  /* border: 1px solid; */
  border-radius: 5px;
  font-size: 0.7rem;
  /* margin: 2rem !important; */
  /* width: 4.5rem; */
  /* min-height: 5rem; */
}

.theoryHours {
  background-color: rgba(162, 227, 248, 0.98);
  font-size: 0.7rem;
  font-weight: 500;
  border-radius: 5px;
  /* margin: 1rem; */
}

.labHours {
  background-color: rgba(179, 255, 187, 0.5);
  font-size: 0.7rem;
  font-weight: 500;
  border-radius: 5px;
  /* max-width: 0.5rem; */
}

.headerRow {
  height: 4rem;
}

.dataRow {
  height: 2rem;
}

.ttContent {
  text-transform: uppercase;
  color: rgba(62, 62, 62, 1);
  background-color: rgba(255, 255, 171, 1);
  cursor: pointer;
  font-weight: 500;
  border-radius: 5px;
  font-size: 0.7rem;
}

.ttContent:hover {
  color: rgb(0, 0, 0);
  font-size: 0.7rem;
  background-color: rgb(250, 179, 218);
  cursor: pointer;
  /* border: 1px solid rgba(255, 14, 5, 0.37);
  box-shadow: 0 8px 32px 0 rgba(255, 14, 5, 0.8); */

  /* width: 110%;
  height:110%; */
}

/* .ttContent::selection{
  color: rgb(0, 0, 0);
  font-size: 0.7rem;
  background-color: rgb(250, 179, 218);
  cursor: pointer;
} */
.lunch {
  /* color: #ffffff;
  font-weight: bold; */
  /* background-color: #c0c0c0; */
  cursor: default;
}

.addCourseDrawerDiv {
}

.filterSlotDrawerDiv{
  margin-top: 0.2rem;
  /* width: 45rem; */
}
.table td {
  /* margin:5rem; */
}

.customCourseInput {
  padding: 1rem;
  margin: 0.5rem;
  border: 0.5px solid rgb(151, 151, 151);
  border-radius: 5px;
}
.customCourseInput:focus {
  box-shadow: 0 0 2px 0px #474747;
  outline: none;
}

::-webkit-scrollbar {
  background: transparent;

  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgb(212, 212, 212);
  width: 6px;
  border-radius: 5px;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.rightDrawer,
.leftDrawer {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.filterSlotDrawer{

}

.waysBox {
  margin: 1rem;
}
.waysBox > div {
  width: 95%;
}
.waysTitle {
  margin-bottom: 1rem;
  font-size: 1.8rem;
  font-weight: 600;
  /* text-align: center; */
}

.changeCoursesBtn {
  margin-top: -1rem;
  /* float: right; */
  margin-left: 45% !important;
  width: 10rem;
  background-color: #d7263d !important;
  /* position: absolute; */
  align-self: center;
  color: #fff !important;
  border: 1px solid #ffffff !important;
  text-transform: none;
}
.changeCoursesBtn:hover {
  border: 1px solid #d7263d !important;
  background-color: #fff !important;
  color: #d7263d !important;
  outline: none;
}
.feedbackBtn{
  margin-top: -1rem;
  float: right;
  /* margin-left: 45% !important; */
  width: 10rem;
  background-color: #ffffff !important;
  align-self: center;
  color: #08b50e !important;
  border: 1px solid #08b50e !important;
  text-transform: none;
}
.feedbackBtn:hover{
  border: 1px solid #000 !important;
  background-color: #fff !important;
  color: #000000 !important;
  outline: none;
}

.warningText{
  font-size: 10px;
  margin-left: 1rem;
}

@media print {
  /* #dontPrintBtn{
    display: none !important;
  } */
}