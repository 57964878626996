.component {
  max-width: 350px;
  min-width: 350px;
  min-height: 350px !important;
  max-height: 350px !important;
  padding-right: 20px;
  position: fixed !important;
  bottom: 20px;
  left: 20px;
  z-index: 9999 !important;
  border-radius: 10px !important;
  animation: swipeIn 0.5s;
  transition: all 0.35s ease-in-out linear;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 15px;
}

.component > div > h1 {
  text-align: left;
  font-size: 15px;
  border-bottom: 1px solid #000;
  padding-bottom: 12px;
  line-height: 1.7;
  border-color: #c1c1c1;
  color: #1c1c1c;
}

@keyframes swipeIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@media (max-width: 1050px) {
  .component {
    display: none;
  }
}

.facultyList {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  margin-top: -2px;
  max-height: 230px !important;
  margin-bottom: 0px !important
}

.facultyList > li {
  padding: none;
  margin: 18px 0px 10px -10px;
}

.listElement {
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.listElement > h4 {
  font-size: 15px;
  text-align: left;
  font-weight: normal;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  padding-top: 0px;
}

.listElement > p {
  font-size: 12px;
  font-style: italic;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  padding-top: 0px;
}

.red {
  color: #ff5252;
}

.green {
  color: #388e3c;
}

.orange {
  color: orange;
}
