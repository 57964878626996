
.ttContent {
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  background-color: rgba(255, 255, 171, 0);
  cursor: pointer;
  /* font-weight: 500; */
  border-radius: 5px;
  font-size: 0.7rem;
}

.ttContent:hover {
  color: rgb(255, 255, 255);
  font-size: 0.7rem;
  background-color: rgba(250, 179, 218, 0);
  /* border: 1px solid #ffffff; */
  outline: 1px solid #fff;
  outline-offset: -1px;
  cursor: pointer;
  /* border: 1px solid rgba(255, 14, 5, 0.37);
    box-shadow: 0 8px 32px 0 rgba(255, 14, 5, 0.8); */

  /* width: 110%;
    height:110%; */
}

.colorChange {
  background-color: rgb(255, 255, 255) !important;
  text-transform: uppercase;
  color: rgba(62, 62, 62, 1);
  cursor: pointer;
  font-weight: 500;
  border-radius: 5px;
  font-size: 0.7rem;
}
