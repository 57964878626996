.courseList {
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  min-height: 100%;
  color: #ffffff;
}
.courseTable {
  width: 100%;

  height: 100%;
}

.credits {
  margin-left: 5px !important;
}

.courseTable th,
td {
  /* border-right: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff; */
}

.tableBody > td {
  border: 1px solid #fff;
}

